
body {
  background-color: #c1ffc1;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.app {
  text-align: center;
  padding: 20px;
}

.app-header {
  padding: 20px;
  border-radius: 10px;
}

.logo {
  width: 100%;
  max-width: 1000px; 
  height: auto;
  margin-bottom: 30px; 
}

.content-section {
  margin-top: 70px;
  margin-bottom: 50px; 
}

h2 {
  font-size: 1.7em;
  margin-top: 0;
  margin-bottom: 40px; 
  color: #333;
}

.team-section {
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.team-member {
  text-align: center;
  padding: 10px;
}

.team-photo {
  width: 150px; 
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

h4 {
  margin-top: 10px;
  font-size: 1em; 
}

h4 a {
  text-decoration: none;
  color: #007bff;
}

h4 a:hover {
  text-decoration: underline;
}

p {
  font-size: 0.85em; 
  color: #666;
}


@media (max-width: 600px) {
  .logo {
    width: 100%;
    max-width: 600px; 
  }

  h2 {
    font-size: 1.2em;
    margin-bottom: 30px; 
  }

  .team-section {
    gap: 20px; 
    max-width: 100%; 
  }

  .team-photo {
    width: 100px; 
    height: 100px;
  }

  h4 {
    font-size: 0.9em; 
  }

  p {
    font-size: 0.75em;
  }
}
